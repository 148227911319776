<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit, validate, reset, invalid }"
    >
        <v-form>
            <v-card>
                <v-card-title>
                    <span class="headline">Variable Form</span>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear v-if="formLoading" indeterminate />
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Name"
                                    rules="required"
                                >
                                    <v-text-field
                                        :error-messages="errors"
                                        v-model="formData.name"
                                        :label="$t('name')"
                                        :disabled="formLoading"
                                        required
                                    />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <ApolloQuery
                                    :query="
                                        require('@/graphql/variables/varaibleSetDropdown.gql')
                                    "
                                >
                                    <template
                                        v-slot="{
                                            result: { loading, error, data },
                                        }"
                                    >
                                        <v-autocomplete
                                            v-if="data"
                                            :items="data.variableSets"
                                            :disabled="formLoading"
                                            :loading="formLoading"
                                            item-text="name"
                                            item-value="id"
                                            v-model="variable_set_id"
                                            label="Variable Set"
                                            clearable
                                            required
                                        />
                                        <v-autocomplete
                                            v-else
                                            label="Loading Variable Sets"
                                        />
                                    </template>
                                </ApolloQuery>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="formData.comment"
                                    :label="$t('comment')"
                                    :disabled="formLoading"
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="formData.definition"
                                    :label="$t('definition')"
                                    :disabled="formLoading"
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="formData.evaluated_definition"
                                    :label="$t('evaluated_definition')"
                                    :disabled="formLoading"
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="formData.field_type"
                                    :label="$t('field_type')"
                                    :disabled="formLoading"
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="formData.LastUpdated"
                                    :label="$t('last_updated')"
                                    :disabled="formLoading"
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="formData.sort_order"
                                    :label="$t('sort_order')"
                                    :disabled="formLoading"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="$emit('variable-form-closed')"
                        :disabled="formLoading"
                        >{{ $t('close') }}</v-btn
                    >
                    <v-btn
                        v-if="formData.id"
                        color="primary darken-1"
                        text
                        :loading="formLoading"
                        :disabled="formLoading"
                        @click="handleSubmit(updateVariable())"
                        >{{ $t('save') }}</v-btn
                    >
                    <v-btn
                        v-else
                        color="primary darken-1"
                        text
                        :disabled="formLoading"
                        :loading="formLoading"
                        @click="handleSubmit(createVariable())"
                        >{{ $t('create') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-form>
    </ValidationObserver>
</template>

<script>
import { required } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})
export default {
    name: 'VariableForm',
    props: {
        variable: {
            type: Object,
            default: () => ({
                id: '',
                name: '',
                comment: '',
                definition: '',
                evaluated_definition: '',
                field_type: '',
                LastUpdated: '',
                sort_order: '',
                variable_set: '',
            }),
        },
    },
    data: () => ({
        error: null,
        mutation: '',
        formLoading: false,
        defaultForm: {
            id: '',
            name: '',
            comment: '',
            definition: '',
            evaluated_definition: '',
            field_type: '',
            LastUpdated: '',
            sort_order: '',
            variable_set: { id: '' },
        },
        formData: {},
    }),
    created() {
        this.formData = { ...this.defaultForm, ...this.variable }
    },
    computed: {
        variable_set_id: {
            // this feels wrong, but it works...
            // getter
            get: function() {
                if (this.variable.variable_set != null) {
                    if (typeof this.variable.variable_set.id !== 'undefined') {
                        return this.variable.variable_set.id
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            },
            // setter
            set: function(newValue) {
                this.formData.variable_set = { id: newValue }
            },
        },
    },
    methods: {
        updateVariable() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return
                } else {
                    this.mutation = require('@/graphql/variables/updateVariable.gql')
                    this.saveForm()
                }
            })
        },
        createVariable() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return
                } else {
                    this.mutation = require('@/graphql/variables/createVariable.gql')
                    this.saveForm()
                }
            })
        },
        saveForm() {
            if (this.formData.variable_set_id == null) {
                this.formData.variable_set_id = {}
            }
            this.formLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: this.mutation,
                    // Parameters
                    variables: {
                        id: this.formData.id,
                        name: this.formData.name,
                        definition: this.formData.definition,
                        evaluated_definition: this.formData
                            .evaluated_definition,
                        field_type: this.formData.field_type,
                        LastUpdated: this.formData.LastUpdated,
                        variable_set: this.variable_set_id,
                        sort_order: this.formData.sort_order,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('variable-form-closed', data)
                    this.$emit('variable-form-saved', data)
                    this.formLoading = false
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update user....
                    this.formLoading = false
                })
        },
    },
    watch: {
        variable: {
            handler: function() {
                this.formData = { ...this.defaultForm, ...this.variable }
            },
            deep: true,
        },
    },
}
</script>
