<template>
    <v-card>
        <v-list-item>
            <v-list-item-avatar color="grey">
                <v-icon dark>mdi-clipboard-list</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                {{ project.name }} Projects
                <v-list-item-subtitle>
                    {{ project.client.name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-text>
            <p>Project Manager: {{ project.manager.name }}<br /></p>
            <span><strong>Variable Sets</strong></span>
            <v-list three-line>
                <v-list-item v-for="set in project.variable_sets" :key="set.id">
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="set.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                            File: {{ set.filename }}<br />
                            {{ set.variables.length }} Variables
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="viewVariableSet(set.id)">
                                    <v-list-item-title>View</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="editVariableSet(set.id)">
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="deleteVariableSet(set.id)">
                                    <v-list-item-title
                                        >Delete</v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="newVariableSet">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New Variable Set</span>
            </v-tooltip>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'ProjectVariableCard',
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        selectedSet: {},
    }),
    computed: {
        variable_count() {
            return this.project.variable_sets.map(a => a.variables).flat()
                .length
        },
    },
    methods: {
        newVariableSet() {
            this.$emit('new-variable-set')
        },
        viewVariableSet(id) {
            this.$router.push({ path: `/variableset/${id}` })
        },
        editVariableSet(id) {
            this.$emit('edit-variable-set', id)
        },
        deleteVariableSet(id) {
            this.$emit('delete-variable-set', id)
        },
    },
}
</script>
