<template>
    <v-container fluid>
        <ApolloQuery
            :query="
                require('@/graphql/project/allProjectsWithVariableSets.gql')
            "
            :variables="{ project_id: project_id, garbage: rando }"
        >
            <template v-slot="{ result: { loading, error, data }, isLoading }">
                <v-progress-linear indeterminate v-if="isLoading" />
                <v-data-iterator
                    v-if="data && !loading"
                    :items="data.allProjects"
                    :search="keywords"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{ itemsPerPageOptions }"
                >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                            >
                                <project-card
                                    :project.sync="item"
                                    @new-variable-set="newVariableSet"
                                    @delete-variable-set="
                                        deleteVariableSet(data)
                                    "
                                    @edit-variable-set="editVariableSet(data)"
                                />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
    </v-container>
</template>

<script>
import ProjectCard from '@/views/variables/ProjectVariableCard'
export default {
    name: 'ProjectsVariablesGrid',
    components: {
        'project-card': ProjectCard,
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        reload: {
            type: Number,
            default: 0,
        },
        project_id: {
            type: String,
        },
    },
    data: () => ({
        itemsPerPageOptions: [4, 8, 12],
        itemsPerPage: 8,
        deleteDialog: false,
        selectedProject: {},
        rando: 0,
    }),
    watch: {
        reload: function(val) {
            this.rando = val
        },
    },
    methods: {
        newVariableSet() {
            this.$emit('new-variable-set')
        },
        editVariableSet(id) {
            this.$emit('edit-variable-set', id)
        },
        deleteVariableSet(id) {
            this.$emit('delete-variable-set', id)
        },
    },
}
</script>
