<template>
    <v-card>
        <v-card-title>
            <h3>Flare Integrations</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search..."
                clearable
                single-line
                hide-details
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" icon fab>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New Flare Variable Set</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <projects-grid
                :keywords.sync="keywords"
                :project_id.sync="project_id"
                :reload.sync="reloadVar"
                @new-variable-set="newVariableSetForm"
                @delete-variable-set="deleteSet(data)"
                @edit-variable-set="editSet(data)"
            />
        </v-card-text>
        <v-dialog v-model="setFormDialog" persistent max-width="600">
            <set-form
                :set.sync="formSet"
                @variable-set-form-saved="
                    closeSetForm()
                    reloadGrid()
                "
                @variable-set-form-closed="closeSetForm()"
            />
        </v-dialog>
    </v-card>
</template>
<script>
import ProjectsGrid from '@/views/variables/ProjectsGrid'
import VariableSetForm from '@/views/variables/VariableSetForm'
export default {
    name: 'variables',
    components: {
        'projects-grid': ProjectsGrid,
        'set-form': VariableSetForm,
    },
    props: ['project_id'],
    data: () => ({
        keywords: '',
        reloadVar: 0,
        formSet: {},
        setFormDialog: false,
    }),
    methods: {
        reloadGrid: function() {
            this.reloadVar++
        },
        newVariableSetForm: function() {
            this.formSet = {}
            this.setFormDialog = true
        },
        closeSetForm: function() {
            this.setFormDialog = false
        },
        closePassForm: function() {
            this.passFormDialog = false
        },
        editSet: function(set) {
            this.formSet = set
            this.setFormDialog = true
        },
        deleteSet: function(id) {
            console.log('deleting a set' + id)
        },
    },
}
</script>
