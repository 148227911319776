var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, validate, reset, invalid }){return [_c('v-form',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Variable Set Form")])]),_c('v-card-text',[(_vm.formLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"SetName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"data-vv-name":"SetName","label":_vm.$t('name'),"disabled":_vm.formLoading,"required":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('description'),"disabled":_vm.formLoading,"required":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('instructions'),"disabled":_vm.formLoading,"required":""},model:{value:(_vm.formData.instructions),callback:function ($$v) {_vm.$set(_vm.formData, "instructions", $$v)},expression:"formData.instructions"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/client/clientsDropdown.gql')},scopedSlots:_vm._u([{key:"default",fn:function({
                                        result: { loading, error, data },
                                    }){return [(data)?_c('ValidationProvider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":data.allClients,"data-vv-name":"Client","error-messages":errors,"disabled":_vm.formLoading,"loading":_vm.formLoading,"item-text":"name","item-value":"id","label":"Client","clearable":"","required":""},model:{value:(_vm.client_id),callback:function ($$v) {_vm.client_id=$$v},expression:"client_id"}})]}}],null,true)}):_c('v-select',{attrs:{"label":"Loading Clients"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/project/projectsDropdown.gql'),"variables":{
                                    client_id: _vm.formData.client_id,
                                }},scopedSlots:_vm._u([{key:"default",fn:function({
                                        result: { loading, error, data },
                                    }){return [(loading)?_c('v-select',{attrs:{"label":"Loading..."}}):(data)?_c('ValidationProvider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"data-vv-name":"Project","error-messages":errors,"items":data.allProjects,"disabled":_vm.formLoading,"loading":loading,"item-text":"name","item-value":"id","label":_vm.$t('project'),"clearable":"","required":""},model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}})]}}],null,true)}):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":"","disabled":_vm.formLoading},on:{"click":function($event){return _vm.$emit('variable-set-form-closed')}}},[_vm._v(_vm._s(_vm.$t('close')))]),(_vm.formData.id)?_c('v-btn',{attrs:{"color":"primary darken-1","text":"","loading":_vm.formLoading,"disabled":_vm.formLoading},on:{"click":function($event){handleSubmit(_vm.updateVariableSet())}}},[_vm._v(_vm._s(_vm.$t('save')))]):_c('v-btn',{attrs:{"color":"primary darken-1","text":"","disabled":_vm.formLoading,"loading":_vm.formLoading},on:{"click":function($event){handleSubmit(_vm.createVariableSet())}}},[_vm._v(_vm._s(_vm.$t('create')))])],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }